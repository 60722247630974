import footerlogo from '../../images/logo-dark.svg'
import { useTranslation } from "react-i18next";

export const Footer = () => {
    const { t } = useTranslation();
    return (

        <div className="my-footer">
            <div className="container">
                <div className="footer-logo">
                    <a href="https://www.hotwireglobal.com/" className="logo">
                        <img className="logo" alt="Hotwire Global" src={footerlogo} />
                    </a>
                </div>
                <div className="row footer-content">
                    <div className="col3 colmd3 col6sm">
                        <h2>{t('footer_services')}</h2>
                        <ul>
                            <li><a href="https://www.hotwireglobal.com/our-services/">{t('footer_our_services')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/our-sectors/">{t('footer_our_sectors')}</a></li>
                        </ul>
                    </div>
                    <div className="col3 colmd3 col6sm">
                        <h2>{t('footer_resources')}</h2>
                        <ul>
                            <li><a href="https://www.hotwireglobal.com/case-studies/">{t('footer_casestudy')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/insights/">{t('footer_blog')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/insights/#insights-whitepapers">{t('footer_whiterpaper')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/insights/#insights-opinions">{t('footer_insights')}</a></li>
                        </ul>
                    </div>
                    <div className="col3 colmd3 col6sm">
                        <h2>{t('footer_about')}</h2>
                        <ul>
                            <li><a href="https://www.hotwireglobal.com/about-us/#experienced-leadership">{t('footer_leadership')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/about-us/#global-partners">{t('footer_global')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/about-us/global-network/">{t('footer_partners')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/insights/#insights-news">{t('footer_newsroom')}</a></li>
                        </ul>
                    </div>
                    <div className="col3 colmd3 col6sm">
                        <h2>{t('footer_work')}</h2>
                        <ul>
                            <li><a href="https://www.hotwireglobal.com/careers/#our-commitment">{t('footer_deib')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/careers/#our-culture">{t('footer_culture')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/careers/#global-benefits">{t('footer_global_benefits')}</a></li>
                            <li><a href="https://www.hotwireglobal.com/careers/#job-listings">{t('footer_job_listing')}</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row footer-content">
                    <div className="col12">
                        <h2>{t('footer_global_reach')}</h2>
                    </div>
                    <div className="col3 colmd3 col6sm">
                        <ul>
                            <li><a href="https://www.hotwireglobal.com.au/">{t('footer_australia')}</a></li>
                            <li><a href="https://www.hotwireglobal.it/">{t('footer_italy')}</a></li>
                            <li><a href="https://www.hotwireglobal.es/">{t('footer_spain')}</a></li>
                        </ul>
                    </div>
                    <div className="col3 colmd3 col6sm">
                        <ul>
                            <li><a href="https://www.hotwireglobal.fr/">{t('footer_france')}</a></li>
                            <li><a href="https://www.hotwireglobal.com.au/">{t('footer_japan')}</a></li>
                            <li><a href="https://www.hotwireglobal.co.uk/">{t('footer_uk')}</a></li>
                        </ul>
                    </div>
                    <div className="col3 colmd3 col6sm">
                        <ul>
                            <li><a href="https://www.hotwireglobal.de/">{t('footer_germany')}</a></li>
                            <li><a href="https://www.hotwireglobal.com.au/">{t('footer_malaysia')}</a></li>
                        </ul>
                    </div>
                    <div className="col3 colmd3 col6sm">
                        <ul>
                            <li><a href="https://www.hotwireglobal.com.au/">{t('footer_india')}</a></li>
                            <li><a href="https://www.hotwireglobal.com.au/">{t('footer_singapore')}</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row footer-btm">
                    <div className="col12">
                        <hr />
                    </div>
                    <div className="col8 txt">
                        <p>Copyright © 2023 Hotwire Global</p>
                        <ul className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-995"><a target="_blank" rel="noreferrer noopener" href="https://www.hotwireglobal.com/privacy-policy/">{t('footer_privacy')}</a></li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-997"><a target="_blank" rel="noreferrer noopener" href="https://www.enero.com/wp-content/uploads/2020/08/Modern-Slavery-Statement-AUG-2020-approved.pdf">{t('footer_slavery')}</a></li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-996"><a target="_blank" rel="noreferrer noopener" href="https://www.hotwireglobal.com/dei-at-hotwire/">Diversity Statement</a></li>
                        </ul>
                    </div>
                    <div className="col4">
                        <ul className="social-icons">
                            <li>
                                <a href="https://www.facebook.com/HotwireGlobal/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5Z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/320860/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M6.94 5a2 2 0 1 1-4-.002a2 2 0 0 1 4 .002ZM7 8.48H3V21h4V8.48Zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68Z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/hotwireglobal">
                                    <i className="dashicons dashicons-x">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
                                        </svg>
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/user/HotwireChannel">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/hotwireglobal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@hotwireglobal">
                                    <i className="dashicons icon-tiktok">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 24">
                                            <path fill="currentColor" d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48z"></path>
                                        </svg>
                                    </i>
                                </a>
                            </li>
                            <i className="dashicons icon-tiktok">
                            </i>
                        </ul>
                    </div>
                </div>
            </div>
        </div>





    )
}
