import { Footer } from "./footer/Footer";
import { Header } from "./navbar/Header";


function ErrorPage() {
    return (
        <>
            <Header />
            <div class="container">
                <div class="wrap404">
                    <h2 class="title ">Page Not Found</h2>
                    <p class="para">We were unable to find that page.</p>
                    <p class="para">Please go to the home page by clicking the button below.</p>
                    <a class="global-btn xl" href="/">Go To Home</a>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ErrorPage;