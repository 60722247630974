import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import translation from './assets/locals/us/translation.json';
import { Link } from 'react-router-dom';
import App from './App';
import './style.css';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

// const Overlay = () => {
//   const [accepted, setAccepted] = useState(false);
//   const { t } = useTranslation();

//   useEffect(() => {
//     const isAccepted = localStorage.getItem('cookieAccepted');
//     if (isAccepted === 'true') {
//       setAccepted(true);
//     }
//   }, []);

//   const handleAccept = () => {
//     localStorage.setItem('cookieAccepted', 'true');
//     setAccepted(true);
//   };

//   if (accepted) {
//     return null; // If cookie is accepted, don't render the overlay
//   }

//   return (
//     <div
//       style={{
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//         zIndex: 9999,
//       }}
//     >
//       <div>
//         <CookieConsent
//           location="bottom"
//           buttonText={t('cookie_bar_button_text')}
//           cookieName="BenchMarkSurveyCookie"
//           style={{ background: "#2B373B" }}
//           buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
//           expires={150}
//           onAccept={handleAccept}
//         >
//           {t('cookie_bar_text')}
//           <Link to="/privacy-policy" style={{ color: "#4e503b" }}>{t('cookie_bar_link_text')}</Link>
//           <button to="/privacy-policy" style={{ color: "#4e503b", background: "none", border: "none", cursor: "pointer" }}>Essentials</button>
//         </CookieConsent>
//       </div>
//     </div>
//   );
// };

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .use(resourcesToBackend((language) => import(`./assets/locals/${language}/translation.json`)))
  .init({
    supportedLngs: ['us', 'fr', 'de', 'au', 'es', 'it', 'uk'],
    fallbackLng: "us",
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: translation,
    },
    react: {
      useSuspense: false
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/* <Overlay /> */}
  </React.StrictMode>
);
