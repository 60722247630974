import styled from "styled-components";

const API_URL = process.env.REACT_APP_API_URL

const ProgressBar = styled.svg
    `
    .progressVal.active{
        animation: progressBar 1s linear 0s 1 forwards;
    }
    @keyframes progressBar{
        to{
            stroke-dasharray: ${(props) => (props.finishto)};
        }
    }
`

ProgressBar.defaultProps = {
    finishto: 0
}

export {
    API_URL,
    ProgressBar
}
