import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../images/hotwire-web-logo.png';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';

const languages = [
    {
        code: 'au',
        name: 'Australia',
        country_code: 'au'
    },
    {
        code: 'fr',
        name: 'Français',
        country_code: 'fr'
    },
    {
        code: 'de',
        name: 'German',
        country_code: 'de'
    },
    {
        code: 'it',
        name: 'Italy',
        country_code: 'it'
    },
    {
        code: 'es',
        name: 'Spain',
        country_code: 'es'
    },
    {
        code: 'uk',
        name: 'United Kingdom',
        country_code: 'uk'
    },  
    {
        code: 'us',
        name: 'United States',
        country_code: 'gb'
    }
];

const GlobeIcon = ({ width = 30, height = 30 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="#fff" className="bi bi-globe" viewBox="0 0 16 16">
            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z" />
        </svg>
    )
};

export const Header = () => {
    const navigate = useNavigate();
    
    const location = useLocation();
    
    useEffect(()=>{
        let language = "us"; // Default to US
        if (location.pathname.startsWith('/it')) {
        language = "it";
        } else if (location.pathname.startsWith('/us')) {
        language = "us";
        } else if (location.pathname.startsWith('/au')) {
        language = "au";
        } else if (location.pathname.startsWith('/fr')) {
        language = "fr";
        } else if (location.pathname.startsWith('/es')) {
        language = "es";
        } else if (location.pathname.startsWith('/uk')) {
        language = "uk";
        }else if (location.pathname.startsWith('/de')) {
        language = "de";
        }
        
        changeLanguage(language);

    },[])

    const [language, setLanguage] = useState('us');

    useEffect(() => {
        if (location.pathname.startsWith("/it")) {
          setLanguage("it");
        } else if (location.pathname.startsWith("/us")) {
          setLanguage("us");
        } else if (location.pathname.startsWith("/au")) {
          setLanguage("au");
        } else if (location.pathname.startsWith("/fr")) {
          setLanguage("fr");
        } else if (location.pathname.startsWith("/es")) {
          setLanguage("es");
        } else if (location.pathname.startsWith("/uk")) {
          setLanguage("uk");
        } else if (location.pathname.startsWith("/de")) {
          setLanguage("de");
        }
      }, [location.pathname]);

// const location = useLocation();
    const changeLanguage = (code) => {
        i18next.changeLanguage(code);
        // Redirect to the corresponding language path
        // navigate(`/${code}`);
    };

    return (
        <section className="my-header">
            <div className="container header-container">
                <div className="row">
                    <Link to={`/${language}`} reloadDocument={true}>
                        <img src={logo} alt="Logo" />
                    </Link>
                    
                        {/* <div className="dropdown">
                            <div className="dropdownButtonWrapper">

                                <button className="dropbtn">
                                    <GlobeIcon />
                                </button>
                            </div>
                            <div className="dropdown-content">
                                <ul>
                                    {languages.map(({ code, name, country_code }) => (
                                        <li key={country_code}>
                                            <button onClick={() => changeLanguage(code)}>{name}</button>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div> */}

                
                </div>
            </div>
        </section>
    );
};
