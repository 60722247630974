



import { UtmContext } from './context/utm';
import PrivacyPolicy from './pages/Privacypolicy';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './sass/main.scss';
import Home from './pages/Home';
import Survey from './pages/Survey';
import Thankyou from './pages/Thankyou';
import Notfound from './pages/Notfound';
import { useEffect, useState } from 'react';
import ErrorPage from './components/ErrorPage';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const Overlay = () => {
  const [accepted, setAccepted] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const [language, setLanguage] = useState('us');

  useEffect(() => {
    const isAccepted = localStorage.getItem('cookieAccepted');
    if (isAccepted === 'true') {
      console.log(isAccepted);
      setAccepted(true);
    } else {
      console.log(isAccepted);
      Cookies.remove('BenchMarkSurveyCookie');
      Cookies.remove('_ga');
      Cookies.remove('_ga_5NEGQ8LH0E');
    } 
    // else if(isAccepted === 'false'){
    //   // localStorage.removeItem('cookieAccepted');
    //   // setAccepted(false);
    // }
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/it")) {
      setLanguage("it");
    } else if (location.pathname.startsWith("/us")) {
      setLanguage("com");
    } else if (location.pathname.startsWith("/au")) {
      setLanguage("com");
    } else if (location.pathname.startsWith("/fr")) {
      setLanguage("fr");
    } else if (location.pathname.startsWith("/es")) {
      setLanguage("es");
    } else if (location.pathname.startsWith("/uk")) {
      setLanguage("com");
    } else if (location.pathname.startsWith("/de")) {
      setLanguage("de");
    }
  }, [location.pathname]);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setAccepted(true);

    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KHWN4R7J');
  };

  const handleDecline = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setAccepted(true);
  };

  if (accepted) return null;

  return (
    <div className="overlayWrap"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
      }}
    >
      <CookieConsent
        location="bottom"
        buttonText={t('cookie_bar_button_text')}
        cookieName="BenchMarkSurveyCookie"
        style={{ background: "#2B373B", textAlign: "center" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={handleAccept}
        enableDeclineButton
        declineButtonText={t('cookie_bar_essential_cookie')}
        onDecline={handleDecline}
      >
        {t('cookie_bar_text')}<span> </span>
        <a href={`https://www.hotwireglobal.${language}/privacy-policy`} target="_blank">{t('cookie_bar_rad_more')}</a>
      </CookieConsent>
    </div>
  );
};

function App() {
  const [utm, setUtm] = useState(null)

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    setUtm(queryParameters)

    localStorage.setItem('sessionId', crypto.randomUUID());
    window.scrollTo(0, 0);
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {utm && (
        <UtmContext.Provider value={utm}>
          <Routes>
            {/* <Route path='/us/*' element={<LanguageRoutes />} />
            <Route path='/fr/*' element={<LanguageRoutes />} />
            <Route path='/de/*' element={<LanguageRoutes />} />
            <Route path='/es/*' element={<LanguageRoutes />} />
            <Route path='/it/*' element={<LanguageRoutes />} />
            <Route path='/au/*' element={<LanguageRoutes to="/it?utm_source=PRWeek&utm_medium=email&utm_campaign=benchmarksurvey" replace />} />
            <Route path='/uk/*' element={<LanguageRoutes to="/it?utm_source=PRWeek&utm_medium=email&utm_campaign=benchmarksurvey" replace />} /> */}
            {/* <Route path='*' element={<ErrorPage />} />
            <Route path="/" element={<Navigate to="/us" replace />} /> */}
            <Route path='/us/*' element={<RedirectUrls />} />
            <Route path='/fr/*' element={<RedirectUrls />} />
            <Route path='/de/*' element={<RedirectUrls />} />
            <Route path='/es/*' element={<RedirectUrls />} />
            <Route path='/it/*' element={<RedirectUrls />} />
            <Route path='/au/*' element={<RedirectUrls to="/it?utm_source=PRWeek&utm_medium=email&utm_campaign=benchmarksurvey" replace />} />
            <Route path='/uk/*' element={<RedirectUrls to="/it?utm_source=PRWeek&utm_medium=email&utm_campaign=benchmarksurvey" replace />} />
            <Route path='*' element={<ErrorPage />} />
            <Route path="/" element={<Navigate to="/us" replace />} />
          </Routes>
        </UtmContext.Provider>
      )}
      <Overlay />
    </BrowserRouter>
  );
}

function RedirectUrls() {
  const [language, setLanguage] = useState('com');
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname)
    
    if (location.pathname.startsWith("/it")) {
      setLanguage("it");
    } else if (location.pathname.startsWith("/us")) {
      setLanguage("com");
    } else if (location.pathname.startsWith("/au")) {
      setLanguage("com.au");
    } else if (location.pathname.startsWith("/fr")) {
      setLanguage("fr");
    } else if (location.pathname.startsWith("/es")) {
      setLanguage("es");
    } else if (location.pathname.startsWith("/uk")) {
      setLanguage("co.uk");
    } else if (location.pathname.startsWith("/de")) {
      setLanguage("de");
    } else {
    }
    window.location.href = `https://hotwireglobal.${language}`
  },[]);

  return null;
}

function LanguageRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </>
  );
}

export default App;

